import React, { PureComponent } from 'react';
import { Picker } from 'emoji-mart';
import enhanceWithClickOutside from 'react-click-outside';
import { delay } from 'lodash';

class EmojiPicker extends PureComponent {
  handleClickOutside() {
    delay(() => this.props.setPicker(false), 10);
  }

  render() {
    const { message, setMessage, config, style } = this.props;
    return (
      <Picker
        color={config.header.color}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          flexShrink: '0',
          overflow: 'hidden',
          transition: 'max-height 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
          ...style,
        }}
        showSkinTones={false}
        sheetSize={32}
        emojiSize={30}
        exclude={['search']}
        showPreview={false}
        onClick={({ native }) => {
          setMessage(`${message}${native} `);
        }}
      />
    );
  }
}

export default enhanceWithClickOutside(EmojiPicker);
