import { useApolloClient } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import { once, isEmpty } from 'lodash';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { HISTORY, SUBSCRIBE_EVENTS, SUBSCRIBE_MESSAGES } from '../../actions/Messages';

export const GET_USER = gql`
  query getChatUser(
    $channelId: ID!
    $email: String
    $firstname: String
    $lastname: String
    $language: String
    $mobile: String
    $phone: String
    $attributes: [InputChatUserAttribute!]
  ) {
    user: getChatUser(
      channelId: $channelId
      email: $email
      firstname: $firstname
      lastname: $lastname
      language: $language
      mobile: $mobile
      phone: $phone
      attributes: $attributes
    ) {
      id
    }
  }
`;

const INIT_CHAT = gql`
  query initChatUserChat($chatUserId: ID!, $channelId: ID!) {
    initChatUserChat(chatUserId: $chatUserId, channelId: $channelId)
  }
`;

// because it only does a cache-first we have to check if the user still exists
// this will never fail exept if the user is removed from the database
const CHECK_USER = gql`
  query doesChatUserExist($chatUserId: ID!, $channelId: ID!, $persistentSlug: String) {
    exist: doesChatUserExist(chatUserId: $chatUserId, channelId: $channelId, persistentSlug: $persistentSlug)
  }
`;

const CheckUser = ({ data = { user: {} }, slug, client, subscribeToMore, channelId, persistor, children }) => {
  const messagesRef = useRef([]);
  const [isTyping, setIsTyping] = useState(false);
  const chatUserId = data.user.id || localStorage.getItem(`chatUserId_${channelId}`);

  const subscribe = useMemo(
    () => () => {
      if (!chatUserId) return;

      subscribeToMore({
        document: SUBSCRIBE_MESSAGES,
        variables: { chatUserId },
        updateQuery: (_, { subscriptionData: { data = {} } = {} }) => {
          // console.log('RECEIVED MESSAGE FROM WS -- ', data);
          // console.log(chatUserId);
          if (
            data &&
            data.messageAdded &&
            ['channels', 'bot', 'users'].includes(data.messageAdded.authorType) &&
            (document.visibilityState === 'hidden' ||
              localStorage.getItem('window_focus') === 'false' ||
              localStorage.getItem('webchat_open') === 'false')
          ) {
            window.parent.postMessage(JSON.stringify({ action: 'new_messages' }), '*');
          }
          let historyResult;
          try {
            historyResult = client.readQuery({
              query: HISTORY,
              variables: { chatUserId },
            });
            // console.log('HISTORY RESULT --', historyResult);
          } catch (e) {
            // console.log('HISTORY RESULT ERROR -- ', e);
          }
          if (historyResult && historyResult.history) {
            const prevMessages = messagesRef.current.slice();
            messagesRef.current = [];
            try {
              const newHistory = [
                ...(historyResult.history || []),
                ...prevMessages.filter(message => !historyResult.history.some(hMessage => hMessage.id === message.id)),
                data.messageAdded,
              ];
              // console.log('HISTORY RESULT WRITE NEW HISTORY -- ', newHistory);
              client.writeQuery({
                query: HISTORY,
                variables: { chatUserId },
                data: { history: newHistory },
              });
            } catch (e) {
              // console.log('HISTORY RESULT WRITE ERROR -- ', e);
            }
            // // In case something would go wrong with cache update
            // messagesRef.current = messagesRef.current.concat(data.messageAdded);
          } else {
            messagesRef.current = messagesRef.current.concat(data.messageAdded);
          }
          setIsTyping(false);
        },
      });
      subscribeToMore({
        document: SUBSCRIBE_EVENTS,
        variables: { chatUserId },
        updateQuery: (_, { subscriptionData: { data: { eventAdded = {} } = { eventAdded: {} } } = {} }) => {
          setIsTyping(isTyping => (eventAdded.isTyping && !isTyping ? true : isTyping));
        },
      });
    },
    [chatUserId]
  );
  useEffect(subscribe, [subscribe]);

  return (
    <Query query={INIT_CHAT} variables={{ chatUserId, channelId: channelId }} skip={Boolean(slug)}>
      {({ loading }) => {
        if (loading) return null;
        return (
          <Query
            fetchPolicy="network-only"
            query={CHECK_USER}
            variables={{
              chatUserId,
              channelId: channelId,
              persistentSlug: slug,
            }}
          >
            {({ data: exist, loading, error }) => {
              data.user.id && localStorage.setItem(`chatUserId_${channelId}`, data.user.id);
              if (loading) return null;
              if (error || (exist && !exist.exist)) {
                persistor.pause();
                persistor.purge().then(() => window.location.reload());
                return <span>ChatUser in cache does not exist, clear localstorage</span>;
              }
              return (
                <Fragment>
                  {children({ children, user: data.user, isTyping, messagesFromSubscription: messagesRef.current })}
                </Fragment>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

const emitUserId = once(id => {
  window.parent.postMessage(JSON.stringify({ action: 'user-created', id }), '*');
});

export default ({
  slug,
  channelId,
  persistor,
  children,
  chatUserId: propsChatUserId,
  session = {},
  saveHistory = true,
  subscribeToMore,
}) => {
  const apolloClient = useApolloClient();
  const browserLocale =
    session.locale || (navigator.languages !== undefined ? navigator.languages[0] : navigator.language || 'DEFAULT');
  const browersLocaleShort = browserLocale.substr(0, 2).toUpperCase();
  const attributes = useMemo(() => {
    const { firstname, lastname, locale, email, mobile, telephone, ...remainingSession } = session || {};
    if (!!remainingSession && !isEmpty(remainingSession)) {
      return Object.entries(remainingSession).reduce((arr, [key, value]) => {
        return arr.concat({ key, value });
      }, []);
    }
  }, [session]);

  const chatUserId = propsChatUserId || localStorage.getItem(`chatUserId_${channelId}`);

  return (
    <Query
      fetchPolicy={saveHistory ? 'cache-first' : 'network-only'}
      query={GET_USER}
      skip={Boolean(chatUserId)}
      variables={{
        channelId,
        email: session.email,
        firstname: session.firstname,
        lastname: session.lastname,
        language: browersLocaleShort || null,
        mobile: session.mobile,
        phone: session.telephone,
        attributes,
      }}
    >
      {getUserProps => {
        if (Boolean(chatUserId)) {
          return (
            <CheckUser
              client={apolloClient}
              loading={false}
              data={{ user: { id: chatUserId, __typename: 'ChatUser' } }}
              channelId={channelId}
              persistor={persistor}
              children={children}
              subscribeToMore={subscribeToMore}
              slug={slug}
            />
          );
        }
        if (getUserProps.loading) return null;
        if (!getUserProps.loading && getUserProps.data && getUserProps.data.user) {
          emitUserId(getUserProps.data.user.id);
        }
        return <CheckUser {...getUserProps} channelId={channelId} persistor={persistor} children={children} />;
      }}
    </Query>
  );
};
