/** @jsx jsx */
import { Global, jsx, css } from '@emotion/core';
import ProximusLight from './assets/fonts/Proximus-Light.woff';
import ProximusRegular from './assets/fonts/Proximus-Regular.woff';
import ProximusBold from './assets/fonts/Proximus-Bold.woff';
import ProximusExtraB from './assets/fonts/Proximus-ExtraB.woff';
import { ThemeProvider as Provider } from 'emotion-theming';
import { ConfigProvider } from './context/ConfigContext';
import { Fragment } from 'react';
const SUPPORTED_LANGUAGES = ['NL', 'FR', 'EN', 'DEFAULT'];

const base64ToObject = base64 => {
  try {
    return JSON.parse(atob(base64));
  } catch (e) {
    return { ERROR: 'JSON parse failed' };
  }
};

const urlObj = base64ToObject(window.location.pathname.split('/')[1] || {});

const width = urlObj.w;

let fontSize = '12px';
// if (width < 750) fontSize = '10px';
if (width < 380) fontSize = '10px';

const globalStyle = css`
  html,
  body {
    height: 100%;
    padding: 0;
    margin: 0;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  * {
    font-family: Proximus;
    font-size: ${fontSize};
    box-sizing: border-box;
  }

  @font-face {
    font-family: 'Proximus';
    font-style: light;
    font-weight: 300;
    src: local('Proximus-Light'), url(${ProximusLight}) format('woff');
  }
  @font-face {
    font-family: 'Proximus';
    font-style: normal;
    font-weight: 400;
    src: local('Proximus-Regular'), url(${ProximusRegular}) format('woff');
  }
  @font-face {
    font-family: 'Proximus';
    font-style: bold;
    font-weight: 600;
    src: local('Proximus-Bold'), url(${ProximusBold}) format('woff');
  }
  @font-face {
    font-family: 'Proximus';
    font-style: bolder;
    font-weight: 800;
    src: local('Proximus-ExtraB'), url(${ProximusExtraB}) format('woff');
  }
  .emoji-mart-scroll {
    height: initial !important;
  }
`;

const themeCreater = theme => ({
  general: {
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
  },
  ...theme,
});

const languageStorage = session => {
  const browserLocale =
    session.locale || (navigator.languages !== undefined ? navigator.languages[0] : navigator.language || 'DEFAULT');
  const browersLocaleShort = browserLocale.substr(0, 2).toUpperCase();

  if (
    !localStorage.getItem('interactWebChatLocale') ||
    localStorage.getItem('interactWebChatLocale') !== browersLocaleShort
  ) {
    localStorage.setItem(
      'interactWebChatLocale',
      SUPPORTED_LANGUAGES.includes(browersLocaleShort) ? browersLocaleShort : 'DEFAULT'
    );
  }
  return localStorage.getItem('interactWebChatLocale');
};

const ThemeProvider = ({ theme, user, channel, children, session = {}, hasNotifications = false, enableUpload }) => {
  if (localStorage.getItem('DEBUG')) {
    console.log(theme);
  }
  const genTheme = themeCreater(theme);
  const config = {
    enableUpload,
    header: { color: theme.header.background.color1, hideHeader: theme.header.hideHeader || false },
    user: { id: user },
    channel: { id: channel },
    translations: {
      locale: languageStorage(session) || 'DEFAULT',
      header: { ...theme.header.title },
      placeholder: { ...theme.chat.placeholder },
    },
    hasUserPicture: !!theme.chat.user.picture.url,
    hasAgentPicture: !!theme.chat.agent.picture.url,
    upload: { enabled: true },
    action: { saveHistory: theme.action.saveHistory },
    hasNotifications: hasNotifications,
  };

  return (
    <Fragment>
      <style>{theme.css}</style>
      <Provider theme={genTheme}>
        <Global styles={globalStyle} />
        <ConfigProvider config={config}>{children}</ConfigProvider>
      </Provider>
    </Fragment>
  );
};

export default ThemeProvider;
