import gql from 'graphql-tag';

export const INITIALIZE_WEBCHAT = gql`
  query channelConfig($channelId: ID!) {
    theme: channelConfig(channelId: $channelId) {
      css
      action {
        logo {
          name
          url
        }
        autoOpen
        saveHistory
      }
      header {
        hideHeader
        title {
          NL
          EN
          FR
          DEFAULT
        }
        background {
          color1
          color2
        }

        textColor
      }
      chat {
        placeholder {
          NL
          EN
          FR
          DEFAULT
        }
        windowPosition
        windowHeight
        backgroundColor
        agent {
          color
          textColor
          picture {
            name
            url
          }
        }
        user {
          color
          textColor
          picture {
            name
            url
          }
        }
      }
    }
  }
`;
