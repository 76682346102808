/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Component } from 'react';
import actionOnClick from '../../utils/actionOnClick';
import PrevButtonIcon from './PrevButton';
import NextButtonIcon from './NextButton';

export default class ChatCarousel extends Component {
  state = { selected: 2 };

  ChatCarouselElement = ({ element, onButtonClick, size, config }) => (
    <div
      css={theme => css`
        overflow: hidden;
        border-radius: 5px;
        margin: 0 2em 0 2rem;
        display: flex;
        flex-direction: column;
        box-shadow: 2px 2px 4px 0 rgba(101, 82, 82, 0.07);
        border: solid 1px rgb(196, 196, 196);
        background-color: ${theme.chat.agent.textColor};
        margin-bottom: 5px;
        position: relative;
        flex-shrink: 0;
      `}
    >
      <div>
        <img
          alt="carousel"
          css={css`
            width: 100%;
            height: auto;
            min-height: 14rem;
          `}
          src={element.image_url}
        />
        {size > 1 && (
          <div
            onClick={() => this.setState({ selected: this.state.selected - 1 })}
            css={theme => css`
              position: absolute;
              top: 25%;
              left: 1px;
              cursor: pointer;
              padding: 0.5rem;
              background-color: ${theme.chat.agent.color};
              border-radius: 0 5px 5px 0;
            `}
          >
            <PrevButtonIcon
              css={theme => css`
                color: ${theme.chat.agent.textColor};
              `}
            />
          </div>
        )}
        {size > 1 && (
          <div
            onClick={() => this.setState({ selected: this.state.selected + 1 })}
            css={theme => css`
              cursor: pointer;
              position: absolute;
              top: 25%;
              right: 1px;
              padding: 0.5rem;
              background-color: ${theme.chat.agent.color};
              border-radius: 5px 0 0 5px;
            `}
          >
            <NextButtonIcon
              css={theme => css`
                color: ${theme.chat.agent.textColor};
              `}
            />
          </div>
        )}
      </div>
      <span
        css={theme => css`
          font-weight: bold;
          margin-top: 3px;
          margin-bottom: 3px;
          margin-left: 8px;
          color: ${theme.chat.agent.color};
        `}
      >
        {element.title}
      </span>
      <span
        css={theme => css`
          word-break: break-all;
          color: ${theme.chat.agent.color}80;
          margin-bottom: 7px;
          margin-left: 8px;
        `}
      >
        {element.subtitle}
      </span>
      {(element.buttons || []).map((button, index) => (
        <div
          onClick={() => actionOnClick(onButtonClick, button, config)}
          css={theme => css`
            border-top: ${theme.chat.agent.textColor} solid 1px;
            text-align: center;
            cursor: pointer;
            padding: 8px 0 8px 0;
            color: ${theme.chat.agent.textColor};
            background-color: ${theme.chat.agent.color};
            &:hover {
              text-decoration: underline;
            }
          `}
        >
          {button.title}
        </div>
      ))}
    </div>
  );

  render() {
    const { elements, config, onButtonClick } = this.props;
    const { selected } = this.state;
    const modulus = Math.abs(selected % elements.length);

    const element = elements[modulus];
    return (
      <div
        css={css`
          padding-bottom: 1rem;
        `}
      >
        <this.ChatCarouselElement
          config={config}
          onButtonClick={onButtonClick}
          element={element.template ? element.template : element}
          size={elements.length}
        />
      </div>
    );
  }
}
