import gql from 'graphql-tag';

export const ADD_MESSAGE = gql`
  mutation sendChatMessage($chatUserId: ID!, $channelId: ID!, $message: String!, $payload: String) {
    sendChatMessage(chatUserId: $chatUserId, channelId: $channelId, message: $message, payload: $payload)
  }
`;

export const SUBSCRIBE_EVENTS = gql`
  subscription ($chatUserId: ID!) {
    eventAdded(chatUserId: $chatUserId) {
      isTyping
    }
  }
`;

export const READ_MESSAGES = gql`
  mutation readMessages($channelId: ID!, $chatUserId: ID!) {
    readMessages(channelId: $channelId, chatUserId: $chatUserId)
  }
`;

export const SUBSCRIBE_MESSAGES = gql`
  subscription ($chatUserId: ID!) {
    messageAdded(chatUserId: $chatUserId) {
      id
      readReceipt {
        deliveredAt
        readAt
      }
      content {
        ... on TextMessage {
          text
        }

        ... on ImageMessage {
          url
        }

        ... on AudioMessage {
          url
        }
        ... on FileMessage {
          url
        }
        ... on VideoMessage {
          url
        }
        ... on ButtonTemplate {
          text
          buttons {
            type
            title
            payload
            url
          }
        }
        ... on QuickReplies {
          text
          quick_replies {
            content_type
            title
            payload
            image_url
          }
        }
        ... on ListTemplate {
          top_element_style
          elements {
            title
            subtitle
            image_url
            default_action {
              type
              url
            }
            buttons {
              type
              title
              payload
              url
            }
          }
          buttons {
            type
            title
            payload
            url
          }
        }
        ... on GenericTemplate {
          elements {
            title
            image_url
            item_url
            buttons {
              type
              title
              payload
              url
            }
            subtitle
          }
        }
      }
      contentType
      authorType
      __typename
    }
  }
`;

export const HISTORY = gql`
  query history($chatUserId: ID!) {
    history(chatUserId: $chatUserId) {
      id
      readReceipt {
        deliveredAt
        readAt
      }
      isActiveConversation
      content {
        ... on TextMessage {
          text
        }

        ... on ImageMessage {
          url
        }

        ... on AudioMessage {
          url
        }

        ... on FileMessage {
          url
        }
        ... on VideoMessage {
          url
        }
        ... on ButtonTemplate {
          text
          buttons {
            type
            title
            payload
            url
          }
        }
        ... on QuickReplies {
          text
          quick_replies {
            content_type
            title
            payload
            image_url
          }
        }
        ... on ListTemplate {
          top_element_style
          elements {
            title
            subtitle
            image_url
            default_action {
              type
              url
            }
            buttons {
              type
              title
              payload
              url
            }
          }
          buttons {
            type
            title
            payload
            url
          }
        }
        ... on GenericTemplate {
          elements {
            title
            image_url
            item_url
            buttons {
              type
              title
              payload
              url
            }
            subtitle
          }
        }
      }
      contentType
      authorType
    }
  }
`;
//

export const SUBSCRIBE_RECEIPTS = gql`
  subscription messageReceipts($chatUserId: ID!) {
    messageReceipts(chatUserId: $chatUserId) {
      id
      readReceipt {
        deliveredAt
        readAt
      }
    }
  }
`;
