import React from 'react';

const SvgComponent = props => (
  <svg width={16} height={16} {...props}>
    <g stroke={'currentColor'} fill="none" fillRule="evenodd">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10.5 12.5l-5-4 5-4"
      />
    </g>
  </svg>
);

export default SvgComponent;
