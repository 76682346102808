/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import actionOnClick from '../../utils/actionOnClick';

const ChatListTemplate = ({ elements, buttons, onButtonClick, config }) => {
  return (
    <div
      css={theme => css`
        color: ${theme.chat.agent.color};
        border: solid 1px ${theme.chat.agent.color};
        background-color: ${theme.chat.agent.textColor};
        overflow: hidden;
        border-radius: 5px;
        margin: 0 2em 0 2rem;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        box-shadow: 2px 2px 4px 0 rgba(101, 82, 82, 0.07);
        margin-bottom: 1rem;
        flex-shrink: 0;
      `}
    >
      {elements.map((element, index) => (
        <div
          key={`element_${index}`}
          css={css`
            display: flex;
            flex-direction: row;
            border-bottom: solid 1px rgb(196, 196, 196);
            padding: 8px;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              width: 70%;
            `}
          >
            <span
              css={css`
                font-weight: bold;
                margin-bottom: 3px;
              `}
            >
              {element.title}
            </span>
            <span
              css={theme => css`
                word-break: break-all;
                color: ${theme.chat.agent.color};
                margin-bottom: 7px;
              `}
            >
              {element.subtitle}
            </span>
            {element.buttons && (
              <div
                key={`buttons_${index}`}
                onClick={() => actionOnClick(onButtonClick, element.buttons[0], config)}
                css={theme => css`
                  &:hover {
                    text-decoration: underline;
                  }
                  border-radius: 5px;
                  padding: 6px;
                  color: ${theme.chat.agent.textColor};
                  cursor: pointer;
                  max-width: max-content;
                  background-color: ${theme.chat.agent.color};
                  border: solid 1px ${theme.chat.agent.color};
                `}
              >
                {element.buttons[0].title}
              </div>
            )}
          </div>
          {element.image_url && (
            <img
              onClick={() => actionOnClick(onButtonClick, element.default_action || null)}
              css={css`
                width: 30%;
                height: 100%;
                align-self: center;
                cursor: pointer;
              `}
              alt={'icon'}
              src={element.image_url}
            />
          )}
        </div>
      ))}
      {buttons && (
        <div
          onClick={() => actionOnClick(onButtonClick, buttons[0], config)}
          css={theme => css`
            text-align: center;
            color: ${theme.chat.agent.textColor};
            padding: 1rem;
            background-color: ${theme.chat.agent.color};
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          `}
        >
          {buttons[0].title}
        </div>
      )}
    </div>
  );
};

export default ChatListTemplate;
