/** @jsx jsx */
import { jsx, css } from '@emotion/core';

const TypingIndicator = () => {
  return (
    <div
      css={css`
        bottom: 80px;
        width: 100%;
        text-align: center;
        font-size: 3rem;
        height: 1rem;
        margin-top: -0.8rem;
      `}
    >
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  );
};

export default TypingIndicator;
