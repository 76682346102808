/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import actionOnClick from '../../utils/actionOnClick';

const supportedButtons = {
  postback: true,
  web_url: true,
  phone_number: true,
  webview: true,
  undefined: false,
};

const ButtonTemplate = ({ payload = {}, onButtonClick, config }) => {
  return (
    <div
      css={theme => css`
        color: ${theme.chat.agent.color};
        border: solid 1px ${theme.chat.agent.color};
        background-color: ${theme.chat.agent.textColor};
        display: flex;
        flex-direction: column;
        margin: 0 2em 0 2rem;
        margin-bottom: 1rem;
        min-height: max-content;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 2px 2px 4px 0 rgba(101, 82, 82, 0.07);

        flex-shrink: 0;
      `}
    >
      <div
        css={theme => css`
          text-align: center;
          padding: 0.5rem;

          font-weight: 500;
          padding: 7px 0 7px 0;
          color: ${theme.chat.agent.color};
        `}
      >
        {payload.text}
      </div>
      {payload.buttons.map((button, index) => (
        <div
          title={!supportedButtons[button.type] ? `${button.type} is not supported by Interact` : ''}
          onClick={() => actionOnClick(onButtonClick, button, config)}
          key={index}
          css={theme => css`
            border-top: ${theme.chat.agent.textColor} solid 1px;
            text-align: center;
            cursor: pointer;
            padding: 8px 0 8px 0;
            color: ${theme.chat.agent.textColor};
            background-color: ${theme.chat.agent.color};
            &:hover {
              text-decoration: underline;
            }
          `}
        >
          {button.title}
        </div>
      ))}
    </div>
  );
};

export default ButtonTemplate;
