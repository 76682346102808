import React, { useState } from 'react';

export default ({ maxHeight = 0, src, alt, onClick }) => {
  const onImgLoad = ({ target: img }) => {
    setHeight(img.naturalHeight);
  };
  const [height, setHeight] = useState(maxHeight);
  return (
    <img
      onClick={onClick}
      style={{
        height: maxHeight < height ? maxHeight : height,
        width: 'auto',
        maxWidth: '100%',
      }}
      onLoad={onImgLoad}
      src={src}
      alt={alt}
    />
  );
};
