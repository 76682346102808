/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import localforage from 'localforage';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ReactComponent as NotificationOff } from '../../../assets/icons/disabled-bell.svg';
import { ReactComponent as NotificationOn } from '../../../assets/icons/enabled-bell.svg';
import { ConfigContext } from '../../../context/ConfigContext';

const HeaderUi = styled.div`
  padding: 1rem;
  background-color: ${({ theme: { header } }) => header.color};
  color: ${({ theme: { header } }) => header.textColor};
  display: flex;
  flex-shrink: 0;
  background: ${({ theme: { header } }) =>
    `linear-gradient(135deg,${header.background.color1} 0%,${header.background.color2} 100%)`};
`;

const Title = styled.span`
  font-size: 1.5rem;
`;
const SubTitle = styled.span`
  font-size: 0.9rem;
`;

const isFirefox = navigator.userAgent.indexOf('Firefox') > 0;

const defaultSettings = isFirefox ? { enabled: false } : { enabled: true };

const Header = () => {
  const config = useContext(ConfigContext);
  const hasNotifications = config.hasNotifications;
  const [notificationSettings, setNotificationSettings] = useState(undefined);
  useEffect(() => {
    localforage.getItem('notification_settings', (err, value) => {
      if (!value && hasNotifications) {
        localforage
          .setItem('notification_settings', defaultSettings)
          .then(() => setNotificationSettings(defaultSettings));
      } else if (!!value) {
        setNotificationSettings(value);
      }
    });
  }, [setNotificationSettings]);
  const onChangeNotifSettings = useCallback(
    async settings => {
      localforage.setItem('notification_settings', settings);
      window.parent.postMessage(JSON.stringify({ action: 'update-notifications', settings }), '*');
      setNotificationSettings(settings);
    },
    [setNotificationSettings]
  );
  return (
    <HeaderUi>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
        <Title id="chatbotTitle">
          {config.translations.header[config.translations.locale] || config.translations.header.DEFAULT}
        </Title>{' '}
        <SubTitle>
          Powered by{' '}
          <a
            style={{ color: 'white' }}
            href="https://www.proximus.be/en/id_cl_interact/companies-and-public-sector/it-services/customer-interactions/interact.html"
          >
            #Interact
          </a>
        </SubTitle>
      </div>
      {notificationSettings && notificationSettings.enabled && (
        <NotificationOn
          style={{ color: 'white', height: '3rem' }}
          onClick={() =>
            onChangeNotifSettings({ ...(notificationSettings || {}), enabled: !notificationSettings.enabled })
          }
        />
      )}
      {notificationSettings && !notificationSettings.enabled && (
        <NotificationOff
          style={{ color: 'white', height: '3rem' }}
          onClick={() =>
            onChangeNotifSettings({ ...(notificationSettings || {}), enabled: !notificationSettings.enabled })
          }
        />
      )}
    </HeaderUi>
  );
};

export default Header;
