/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Fragment, useContext } from 'react';
import { ConfigContext } from '../../context/ConfigContext';
import { ReactComponent as ReadReceiptIcon } from '../../assets/icons/sent.svg';
import { ReactComponent as InTransitIcon } from '../../assets/icons/in-transit.svg';

const ContainerUI = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  width: 100;
  padding-bottom: 1rem;
  :first-child {
    padding-top: 1rem;
  }
`;

const AgentProfile = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 1rem;
  background: ${({
    theme: {
      chat: { agent },
    },
  }) => `url(${agent.picture.url})`};
  background-color: ${({
    theme: {
      chat: { agent },
    },
  }) => agent.color};
  background-size: contain;
  background-repeat: no-repeat;
`;

const UserProfile = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 1rem;
  background: ${({
    theme: {
      chat: { user },
    },
  }) => `url(${user.picture.url})`};
  background-color: ${({
    theme: {
      chat: { user },
    },
  }) => user.color};
  background-size: contain;
  background-repeat: no-repeat;
`;

const AgentTextBalloonUI = styled.div`
  background-color: ${({ theme }) => theme.chat.agent.color};
  color: ${({ theme }) => theme.chat.agent.textColor};
  border-radius: 0px 5px 5px 5px;

  padding: 1rem;
  max-width: 75%;
  word-break: break-word;

  flex-shrink: 0;
  word-break: break-word;
  display: inline-block;
  padding-top: 0.66rem;
  padding-bottom: 0.66rem;
  padding-right: 1rem;
  padding-left: 1rem;
  position: relative;
  display: inline-block;

  border: ${({ theme }) => `1px solid ${theme.chat.agent.color}`};

  &::before,
  ::after {
    content: ' ';
    right: 100%;
    top: -1px;
    position: absolute;
    border-width: initial;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
  }
  &::before {
    border-right-color: ${({ theme }) => theme.chat.agent.color};
    border-width: 0px 0.7em 1.1rem;
  }
  &::after {
    top: 0;
    right: calc(100%);
    border-right-color: ${({ theme }) => theme.chat.agent.color};
    border-width: 0px 0.5em 0.8rem;
  }
`;
const UserTextBalloonUI = styled.div`
  background-color: ${({ theme }) => theme.chat.user.color};
  color: ${({ theme }) => theme.chat.user.textColor};
  border-radius: 5px 0px 5px 5px;
  margin-right: 11px;
  padding: 1rem;
  max-width: 75%;
  word-break: break-word;
  margin-left: auto;

  flex-shrink: 0;
  word-break: break-word;
  display: inline-block;
  padding-top: 0.66rem;
  padding-bottom: 0.66rem;
  padding-right: 1rem;
  padding-left: 1rem;
  position: relative;
  display: inline-block;

  border: ${({ theme }) => `1px solid ${theme.chat.user.color}`};

  &::after {
    content: ' ';
    left: calc(99%);
    top: -1px;
    position: absolute;
    border-width: 0px 1em 1rem;
    border-style: solid;
    border-color: ${({ theme }) => `transparent transparent transparent ${theme.chat.user.color}`};
  }
`;

const Balloon = ({ authorType, children, readReceipt }) => {
  const config = useContext(ConfigContext);
  return (
    <ContainerUI>
      {['channels', 'bot', 'users'].includes(authorType) ? (
        <Fragment>
          {config.hasAgentPicture && <AgentProfile />}
          <AgentTextBalloonUI>{children}</AgentTextBalloonUI>
        </Fragment>
      ) : (
        <Fragment>
          <UserTextBalloonUI>{children}</UserTextBalloonUI>
          {config.hasUserPicture && <UserProfile />}
          {!readReceipt && <InTransitIcon style={{ marginTop: 'auto', width: '1rem' }} />}
          {!!readReceipt && (
            <ReadReceiptIcon
              style={{
                marginTop: 'auto',
                width: '1rem',
                ...(!readReceipt.readAt ? { color: '#464646' } : { color: '#5C2D91' }),
              }}
            />
          )}
        </Fragment>
      )}
    </ContainerUI>
  );
};

export default Balloon;
