import React, { useState, useEffect } from 'react';
import ChatContainer from '../ChatContainer';

export default ({ user, isTyping, messagesFromSubscription }) => {
  const [loaded] = useState(true);
  useEffect(() => {
    window.parent.postMessage(JSON.stringify({ action: 'LOADED' }), '*');
  }, [loaded]);

  const setFocus = (value: string) => {
    localStorage.setItem('window_focus', value);
  };
  const setWebchatOpen = (value: string) => {
    localStorage.setItem('webchat_open', value);
  };

  useEffect(() => {
    window.addEventListener('message', event => {
      try {
        const parsedData = JSON.parse(event.data);
        if (parsedData.action === 'window_focus') return setFocus('true');
        if (parsedData.action === 'window_blur') return setFocus('false');
        if (parsedData.action === 'chat_open') return setWebchatOpen('true');
        if (parsedData.action === 'chat_close') return setWebchatOpen('false');
      } catch {}
    });
  }, []);

  return (
    <div
      onFocus={() => setFocus('true')}
      // onBlur={() => setFocus('false')}
      style={{
        position: 'fixed',
        right: '0px',
        bottom: '0px',
        zIndex: '1400',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexShrink: '0',
          height: '100%',
          width: '100%',
        }}
      >
        <ChatContainer user={user} isTyping={isTyping} messagesFromSubscription={messagesFromSubscription} />
      </div>
    </div>
  );
};
